import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import {
  Box,
  Columns,
  Text,
  ContentBlock,
  Divider,
  Stack,
} from "@64labs/bowline-design-system"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./work.css"

const DesktopBottomSection = ({ data }) => (
  <Box display={["none", "none", "flex", "flex"]} paddingY="xlarge">
    <Box paddingX="xlarge">
      <Box style={{ maxWidth: 140, margin: "0 auto 16px" }}>
        <Image
          style={{ borderRadius: 6 }}
          fluid={data.bottomImage1.childImageSharp.fluid}
        />
      </Box>
      <Stack space="smallish">
        <Text heading as="h5" align="center">
          Engage
        </Text>
        <Text align="center">
          We start every project by asking a lot of questions. We want to
          understand your company, your culture, your challenges and your goals.
        </Text>
      </Stack>
    </Box>
    <Box paddingX="xlarge">
      <Box style={{ maxWidth: 140, margin: "0 auto 16px" }}>
        <Image
          style={{ borderRadius: 6 }}
          fluid={data.bottomImage2.childImageSharp.fluid}
        />
      </Box>
      <Stack space="smallish">
        <Text heading as="h5" align="center">
          Implement
        </Text>
        <Text align="center">
          We build your site in one-week sprints and deliver working software at
          the end of each of them. Your project will never disappear into a
          black hole.
        </Text>
      </Stack>
    </Box>
    <Box paddingX="xlarge">
      <Box style={{ maxWidth: 140, margin: "0 auto 16px" }}>
        <Image
          style={{ borderRadius: 6 }}
          fluid={data.bottomImage3.childImageSharp.fluid}
        />
      </Box>
      <Stack space="smallish">
        <Text heading as="h5" align="center">
          Support
        </Text>
        <Text align="center">
          After launch, we stand behind our work and improve it. We review
          results each quarter with you. If you need something added, we are
          there for you.
        </Text>
      </Stack>
    </Box>
  </Box>
)

const MobileBottomSection = ({ data }) => (
  <Box
    style={{ flexDirection: "column" }}
    display={["flex", "flex", "none", "none"]}
    paddingY="gutter"
  >
    <Box marginBottom="gutter" display="flex">
      <Box marginRight="small" style={{ minWidth: 70 }}>
        <Image
          style={{ borderRadius: 6 }}
          fluid={data.bottomImage1.childImageSharp.fluid}
        />
      </Box>
      <Box>
        <Stack space="smallish">
          <Text heading as="h5">
            Engage
          </Text>
          <Text size="small">
            We start every project by asking a lot of questions. We want to
            understand your company, your culture, your challenges and your
            goals.
          </Text>
        </Stack>
      </Box>
    </Box>
    <Box marginBottom="gutter" display="flex">
      <Box marginRight="small" style={{ minWidth: 70 }}>
        <Image
          style={{ borderRadius: 6 }}
          fluid={data.bottomImage2.childImageSharp.fluid}
        />
      </Box>
      <Box>
        <Stack space="smallish">
          <Text heading as="h5">
            Implement
          </Text>
          <Text size="small">
            We build your site in one-week sprints and deliver working software
            at the end of each of them. Your project will never disappear into a
            black hole.
          </Text>
        </Stack>
      </Box>
    </Box>
    <Box marginBottom="gutter" display="flex">
      <Box marginRight="small" style={{ minWidth: 70 }}>
        <Image
          style={{ borderRadius: 6 }}
          fluid={data.bottomImage3.childImageSharp.fluid}
        />
      </Box>
      <Box>
        <Stack space="smallish">
          <Text heading as="h5">
            Support
          </Text>
          <Text size="small">
            After launch, we stand behind our work and improve it. We review
            results each quarter with you. If you need something added, we are
            there for you.
          </Text>
        </Stack>
      </Box>
    </Box>
  </Box>
)

const WorkPage = ({ data }) => {
  const [projectOrder, setProjectOrder] = useState(data.clientList.item)
  const [currentFilter, setCurrentFilter] = useState("")

  useEffect(() => {
    if (window && window.location.hash) {
      setCurrentFilter(
        window.location.hash.replace("#", "").replace("%20", " ")
      )
    }
  }, [])

  useEffect(() => {
    if (currentFilter === "") {
      setProjectOrder(data.clientList.item)
    } else {
      const newArr = projectOrder.slice()
      newArr.sort((a, b) => {
        const clientWorkMatchA = data.allContentfulClientWork.edges.find(
          ({ node }) => node.clientName === a.clientName
        )
        const clientWorkMatchB = data.allContentfulClientWork.edges.find(
          ({ node }) => node.clientName === b.clientName
        )
        const aIncludesFilter = clientWorkMatchA.node.projectCategory.some(
          ({ categoryName }) => categoryName === currentFilter
        )
        const bIncludesFilter = clientWorkMatchB.node.projectCategory.some(
          ({ categoryName }) => categoryName === currentFilter
        )
        let returnVal = 0
        if (aIncludesFilter && !bIncludesFilter) {
          returnVal = -1
        } else if (bIncludesFilter && !aIncludesFilter) {
          returnVal = 1
        }
        return returnVal
      })
      setProjectOrder(newArr)
    }
  }, [currentFilter]) /* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <Layout showPageHeader pageHeaderText="Select client work">
      <SEO
        title="Working With Us"
        description="We build mobile and responsive websites in rapid time using a highly transparent and collaborative agile process."
      />

      <ContentBlock
        style={{ height: 80 }}
        align="center"
        display={["none", "none", "flex"]}
      >
        <Link onClick={() => setCurrentFilter("")} to="/work/">
          <Text
            tone={currentFilter === "" ? "brandAccent" : "neutral"}
            paddingRight="medium"
          >
            show all
          </Text>
        </Link>
        {data.categoryList.item.map(item => {
          return (
            <Link
              key={item.categoryName}
              onClick={() => setCurrentFilter(item.categoryName)}
              to={`/work/#${item.categoryName}`}
            >
              <Text
                tone={
                  currentFilter === item.categoryName
                    ? "brandAccent"
                    : "neutral"
                }
                paddingRight="medium"
              >
                {item.categoryName}
              </Text>
            </Link>
          )
        })}
      </ContentBlock>

      <Box background="subtleLight">
        <ContentBlock>
          <Columns
            gap={["xsmall", "small", "gutter"]}
            cols={[1, 2, 3, 4]}
            paddingY={["small", "medium"]}
          >
            {projectOrder.map(({ clientName }, index) => {
              let fade = false
              const path = `/work/${clientName
                .replace(/ /g, "-")
                .replace(/'/g, "")
                .toLowerCase()}`
              const clientWorkMatch = data.allContentfulClientWork.edges.find(
                ({ node }) => node.clientName === clientName
              )
              if (
                currentFilter !== "" &&
                clientWorkMatch.node.projectCategory &&
                !clientWorkMatch.node.projectCategory.some(
                  ({ categoryName }) => categoryName === currentFilter
                )
              ) {
                fade = true
              }
              return (
                <Box
                  className={`${fade ? "work__fade" : ""}`}
                  key={`work-page-item-${index}`}
                  background="white"
                >
                  <Link
                    aria-label={path}
                    to={`/${path}`}
                    className="work-page-item--zoomable"
                    style={{ display: "block" }}
                  >
                    <Box className="work-page-item__main-image">
                      {index < 2 ?
                        // For Performance reasons, load the first few images eagerly with no LQIP
                        // LCP Element may not be consistent across devices
                        <Image
                        loading="eager"
                        fadeIn={false}
                        alt={
                          clientWorkMatch.node.clientWorkMainImage.description
                        }
                        imgStyle={{ transition: "all 500ms ease-out" }}
                        backgroundColor="#ddd"
                        fluid={clientWorkMatch.node.clientWorkMainImage.fluid}
                        />
                      :
                        <Image
                        alt={
                          clientWorkMatch.node.clientWorkMainImage.description
                        }
                        imgStyle={{ transition: "all 500ms ease-out" }}
                        backgroundColor="#ddd"
                        fluid={clientWorkMatch.node.clientWorkMainImage.fluid}
                        />
                      }
                    </Box>
                    <Box
                      display="flex"
                      align="center"
                      justify="center"
                      paddingY="xsmall"
                      style={{ width: "100%" }}
                    >
                      <Image
                        alt={clientWorkMatch.node.clientLogo.description}
                        fluid={clientWorkMatch.node.clientLogo.fluid}
                        style={{ width: "100%", maxWidth: "50%" }}
                      />
                    </Box>
                  </Link>
                </Box>
              )
            })}
          </Columns>
          <Stack paddingY={["gutter", "gutter", "xlarge"]} space="small">
            <Text size="xlarge" style={{ color: "#d80a86" }} heading as="h2">
              How we work
            </Text>
            <Text>
              The foundation of <b>64labs’</b> philosophy is collaboration and
              transparency. From the first meeting and throughout the project
              you will notice the difference. We demand engaged clients and in
              return, we offer complete visibility into the entire creative
              process.
            </Text>
          </Stack>
          <Divider />
          <DesktopBottomSection data={data} />
          <MobileBottomSection data={data} />
        </ContentBlock>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulClientWork {
      edges {
        node {
          clientName
          projectCategory {
            categoryName
          }
          clientWorkMainImage {
            description
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          clientLogo {
            description
            fluid(
              maxWidth: 200
              maxHeight: 100
              quality: 100
              resizingBehavior: PAD
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    categoryList: contentfulList(title: { eq: "Project Category List" }) {
      item {
        ... on ContentfulProjectCategory {
          categoryName
        }
      }
    }
    clientList: contentfulList(title: { eq: "Client Work Order" }) {
      item {
        ... on ContentfulClientWork {
          clientName
        }
      }
    }
    bottomImage1: file(relativePath: { eq: "img-work-engage.png" }) {
      childImageSharp {
        fluid(maxWidth: 140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bottomImage2: file(relativePath: { eq: "img-work-implement.png" }) {
      childImageSharp {
        fluid(maxWidth: 140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bottomImage3: file(relativePath: { eq: "img-work-support.png" }) {
      childImageSharp {
        fluid(maxWidth: 140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default WorkPage
